<template>
  <v-container class="px-0 pt-3 pb-8 pb-md-16">
    <v-table class="table">
      <thead>
        <tr class="table-header">
          <th
            id="first-column-header"
            class="table-header-row pa-5 text-left"
            :colspan="customTable.table_type === TableType.TwoColumnsWithMergedHeader ? '4' : '1'">
            {{
              customTable.table_type === TableType.TwoColumnsWithMergedHeader
                ? activeTranslation?.header_text
                : activeTranslation?.first_column_header
            }}
          </th>
          <th
            v-if="customTable.table_type === TableType.FourColumnsIndividualHeaders"
            id="second-column-header"
            class="table-header-row pa-5 text-left">
            {{ activeTranslation?.second_column_header }}
          </th>
          <th
            v-if="customTable.table_type === TableType.FourColumnsIndividualHeaders"
            id="third-column-header"
            class="table-header-row pa-5 text-left">
            {{ activeTranslation?.third_column_header }}
          </th>
          <th
            v-if="customTable.table_type === TableType.FourColumnsIndividualHeaders"
            id="fourth-column-header"
            class="table-header-row pa-5 text-left">
            {{ activeTranslation?.fourth_column_header }}
          </th>
        </tr>
      </thead>
      <!-- table_content == 4 // table_entry == 2 -->
      <tbody v-if="customTable.table_type === TableType.FourColumnsIndividualHeaders">
        <tr v-for="(content, index) in shownContent" :key="index" class="table-row">
          <td class="table-row-header py-3" v-html="sanitizeHtml(content.first_column_content)" />
          <td class="py-3" v-html="sanitizeHtml(content.second_column_content)" />
          <td class="py-3" v-html="sanitizeHtml(content.third_column_content)" />
          <td class="py-3 text-break" v-html="sanitizeHtml(content.fourth_column_content)" />
        </tr>
        <tr>
          <td colspan="4" @click="showAllContent = !showAllContent">
            <ButtonText class="w-100 my-4">
              <v-row justify="center">
                <v-col class="pb-0 text-center font-weight-bold" cols="12">
                  <span v-if="!showAllContent">{{ t('show_more_content') }}</span>
                  <span v-if="showAllContent">{{ t('show_less_content') }}</span>
                </v-col>
                <v-col class="pt-0 text-center" cols="12">
                  <v-icon class="mdi-chevron">
                    {{ showAllContent ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-col>
              </v-row>
            </ButtonText>
          </td>
        </tr>
      </tbody>
      <tbody v-if="customTable.table_type === TableType.TwoColumnsWithMergedHeader">
        <tr v-for="(content, index) in shownContent" :key="index" class="table-row">
          <td class="table-row-header py-3" v-html="sanitizeHtml(content.first_column_content)" />
          <td class="py-3 text-break" v-html="sanitizeHtml(content.second_column_content)" />
        </tr>
        <tr>
          <td colspan="4" @click="showAllContent = !showAllContent">
            <ButtonText class="w-100 my-4">
              <v-row justify="center">
                <v-col class="pb-0 text-center font-weight-bold" cols="12">
                  <span v-if="!showAllContent">{{ t('show_more_content') }}</span>
                  <span v-if="showAllContent">{{ t('show_less_content') }}</span>
                </v-col>
                <v-col class="pt-0 text-center" cols="12">
                  <v-icon class="mdi-chevron">
                    {{ showAllContent ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-col>
              </v-row>
            </ButtonText>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-container>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import { TableType } from '~/types/enums'
import type { CustomTable, CustomTableTranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const { locale, locales, t } = useI18n()
const showAllContent = ref(false)

const props = defineProps({
  customTable: {
    type: Object as PropType<CustomTable>,
    default: () => ({}),
  },
})

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslation = computed(() => {
  return customTable.value.translations?.find(
    (t: CustomTableTranslation) => t.languages_code === currentLocaleIso.value,
  )
})

const { customTable } = toRefs(props)

const shownContent = computed(() => {
  if (showAllContent.value)
    return customTable.value.table_type === TableType.TwoColumnsWithMergedHeader
      ? activeTranslation.value?.table_entry
      : activeTranslation.value?.table_content
  return customTable.value.table_type === TableType.TwoColumnsWithMergedHeader
    ? activeTranslation.value?.table_entry?.slice(0, 4)
    : activeTranslation.value?.table_content?.slice(0, 4)
})
</script>

<style lang="scss" scoped>
.table {
  max-width: 1350px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(75, 136, 255, 0.15);
  .table-header {
    color: rgb(var(--v-theme-white));
    background-color: rgb(var(--v-theme-secondary));
    .table-header-row {
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 22px;
    }
  }
  .table-row:not(:last-child) {
    td {
      border-bottom: thin solid rgb(var(--v-theme-border)) !important;
    }
  }
  .table-row-header {
    border-right: thin solid rgb(var(--v-theme-border));
  }
  tr td {
    vertical-align: top;
  }

  tr td:first-child {
    font-weight: 700;
  }
}

.table :deep(ul) {
  list-style-position: inside;
}

@media (max-width: 959px) {
  .table {
    border-radius: 0px;
    th {
      font-size: 14px !important  ;
    }
    td {
      font-size: 12px;
    }
  }
}
</style>
